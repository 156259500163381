.cds--file-container {
  display: none !important;
}

.form-step {
  .summary-header {
    margin-bottom: 2rem;

    .details-section {
      background: #ffffff; // Different background from other sections
      border: 2px solid #e0e0e0; // Add border instead of background color
      padding: 2rem; // Slightly more padding
      border-radius: 4px;
      margin-bottom: 1.5rem;

      &:hover {
        border-color: #c6c6c6;
        transition: border-color 0.15s;
      }

      .details-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // Two columns layout
        gap: 1.5rem;
        background: transparent; // No background since parent is white
        padding: 0; // Remove padding since parent has padding

        .details-item {
          .label {
            font-size: 0.875rem;
            color: #525252;
            font-weight: 500;
            text-transform: uppercase; // Make labels stand out
            letter-spacing: 0.02em;
          }

          .value {
            color: #161616;
            font-size: 1.125rem; // Slightly larger
            font-weight: 600;
          }
        }
      }
    }
  }

  .emissions-category {
    margin-bottom: 2rem;

    .category-title {
      font-size: 1rem;
      font-weight: 600;
      color: #161616;
      margin-bottom: 1rem;
    }

    .emission-item {
      background: #f4f4f4;
      padding: 1.25rem;
      border-radius: 4px;
      margin-bottom: 1rem;

      .emission-name {
        margin-bottom: 1rem;

        .value {
          font-size: 1.125rem;
          font-weight: 600;
        }
      }

      .emission-details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;

        .detail-item {
          .label {
            font-size: 0.875rem;
            color: #525252;
            display: block;
            margin-bottom: 0.25rem;
          }

          .value {
            font-weight: 500;
          }

          &.highlight {
            background: #ffffff;
            padding: 1rem;
            border-radius: 4px;

            .value {
              font-size: 1.25rem;
              font-weight: 600;
              color: #161616;
            }
          }
        }
      }
    }
  }

  .power-comparison-item {
    background: #f4f4f4;
    padding: 1.25rem;
    border-radius: 4px;
    margin-bottom: 1rem;

    &.highlight {
      background: #e0e0e0;
      border-left: 4px solid #0f62fe;
    }

    &.current {
      background: #edf5ff;
      border-left: 4px solid #0f62fe;
    }

    .power-level {
      margin-bottom: 1rem;

      .value {
        font-size: 1.125rem;
        font-weight: 600;
        color: #161616;
      }
    }

    .examples {
      .example-list {
        list-style: none;
        padding: 0;
        margin: 0.5rem 0 0 0;

        li {
          margin-bottom: 0.5rem;
          color: #525252;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .emissions-comparison {
    margin-bottom: 2rem;

    .comparison-section {
      background: #f4f4f4;
      padding: 1.5rem;
      border-radius: 4px;
      margin-bottom: 1rem;

      &:hover {
        background: #eaeaea;
        transition: background-color 0.15s;
      }
    }

    .comparison-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h4 {
        margin: 0;
        font-weight: 600;
        color: #161616;
      }
    }

    .comparison-values {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
      background: #ffffff;
      padding: 1rem;
      border-radius: 4px;

      .value-item {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .label {
          font-size: 0.875rem;
          color: #525252;
        }

        .value {
          font-size: 1.25rem;
          font-weight: 600;
          color: #161616;

          &.warning {
            color: #da1e28;
          }

          &.success {
            color: #24a148;
          }
        }
      }
    }
  }

  .installation-header {
    background: #ffffff;
    border: 2px solid #e0e0e0;
    padding: 2rem;
    margin-bottom: 2rem;

    .installation-name {
      font-size: 1.75rem;
      font-weight: 600;
      color: #161616;
      margin: 0 0 1rem 0;
    }

    .economic-activity {
      .label {
        font-size: 0.875rem;
        color: #525252;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        display: block;
        margin-bottom: 0.25rem;
      }

      .value {
        font-size: 1.25rem;
        font-weight: 500;
        color: #161616;
      }
    }
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    .details-section {
      height: 100%;

      .details-header {
        margin-bottom: 1rem;

        h4 {
          font-size: 1.125rem;
          font-weight: 600;
          color: #161616;
          margin: 0;
        }
      }
    }
  }

  .details-section {
    background: #f4f4f4;
    padding: 1.5rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;

    h4 {
      margin: 0 0 1rem 0;
      font-weight: 600;
      color: #161616;
    }

    .details-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background: #ffffff;
      padding: 1rem;
      border-radius: 4px;

      .details-item {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .label {
          font-size: 0.875rem;
          color: #525252;
        }

        .value {
          color: #161616;
        }
      }
    }
  }

  .validation-hints {
    .hint-section {
      background: #f4f4f4;
      padding: 1.5rem;
      border-radius: 4px;
      margin-bottom: 1rem;
      border-left: 4px solid #0f62fe;

      h5 {
        font-size: 1rem;
        font-weight: 600;
        color: #161616;
        margin: 0 0 1rem 0;
      }

      .hint-content {
        .hint-emphasis {
          font-weight: 500;
          color: #161616;
          margin-bottom: 1rem;
        }
      }

      ul {
        margin: 0;
        padding-left: 1.5rem;

        li {
          color: #525252;
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .production-section {
    margin-top: 2rem;

    .production-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      background: #f4f4f4;
      padding: 1.5rem;
      border-radius: 4px;

      .details-item {
        text-align: center;

        .label {
          font-size: 0.875rem;
          color: #525252;
          display: block;
          margin-bottom: 0.5rem;
        }

        .value.highlight {
          font-size: 1.5rem;
          font-weight: 600;
          color: #161616;
          display: block;
        }
      }
    }
  }

  // Tabs customization
  .cds--tabs {
    margin-bottom: 2rem;
  }

  .cds--tab-content {
    padding: 1rem 0;
  }

  // Accordion customization
  .cds--accordion {
    margin: 1rem 0;

    .cds--accordion__item {
      border-top: 1px solid #e0e0e0;

      &:last-child {
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }

  // Meter chart customization
  .chart-wrapper {
    margin: 2rem 0;
    padding: 1rem;
    background: #ffffff;
    border-radius: 4px;
  }

  // Notification spacing
  .cds--inline-notification {
    margin-bottom: 1rem;
  }

  // Button set container
  .step-btn-set-container {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e0e0e0;

    .step-btn-set {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  // TextArea customization
  .cds--text-area {
    resize: vertical;
    min-height: 100px;
  }

  // Responsive adjustments
  @media (max-width: 672px) {
    .summary-header {
      .details-section {
        padding: 1.5rem;

        .details-content {
          grid-template-columns: 1fr; // Stack on mobile
          gap: 1rem;
        }
      }
    }
    .emission-details {
      grid-template-columns: 1fr !important;
    }

    .comparison-values {
      grid-template-columns: 1fr !important;
      gap: 1rem !important;
    }

    .details-content {
      padding: 0.75rem !important;
    }
  }

  // Helper classes
  .mt-4 {
    margin-top: 1rem;
  }

  .text-gray {
    color: #525252;
  }
}

.renewable-energy-notice {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #deffee;
  border: 1px solid #57bb8a;
  border-radius: 0.25rem;

  h5 {
    color: #0a6640;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  p {
    color: #137752;
    margin-bottom: 0.5rem;
  }

  .progress-bar {
    height: 0.5rem;
    background-color: #e0e0e0;
    border-radius: 1rem;
    overflow: hidden;
    margin: 0.5rem 0;

    .progress-fill {
      height: 100%;
      background-color: #57bb8a;
      transition: width 0.3s ease;
    }
  }

  .percentage-display {
    font-weight: 600;
    color: #0a6640;
  }

  .threshold-info {
    font-size: 0.75rem;
    color: #137752;
    margin-top: 0.25rem;
  }
}

/* Enhanced status indicator for renewable cases */
.status-indicator {
  display: flex;
  align-items: center;
  font-weight: 500;

  &.renewable {
    color: #0a6640;
    
    .icon {
      color: #0a6640;
      margin-right: 0.5rem;
    }
  }
}

/* Enhance the comparison values styling for renewable energy */
.comparison-values {
  .value-item {
    .value.renewable-success {
      color: #0a6640 !important;
    }
  }
}

/* Additional styles for the notification banner */
.cds--inline-notification.renewable-notification {
  background-color: #deffee;
  border-left-color: #0a6640;

  .cds--inline-notification__icon {
    fill: #0a6640;
  }

  .cds--inline-notification__text-wrapper {
    .cds--inline-notification__title {
      color: #0a6640;
    }

    .cds--inline-notification__subtitle {
      color: #137752;
    }
  }
}
