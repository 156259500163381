.footer {
  //margin: 1rem 0;
  margin-bottom: 2rem;
  text-align: center;
}

.footer-sticky {
  z-index: 1000;
  left: 50%;
  bottom: 2rem;
  position: fixed;
}