.warning-details {
  width: 100%;

  .warning-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    color: #525252;

    .warning-icon {
      fill: #f1c21b;
      margin-right: 0.5rem;
      flex-shrink: 0;
    }
  }
}

.remarks-section {
  width: 100%;

  .cds--text-area {
    resize: vertical;
    min-height: 100px;
  }
}

.mt-4 {
  margin-top: 1rem;
}
