@use '@carbon/themes/scss/tokens' as *;

.bodyWrapper {
  margin-top: 3rem;
}

.bodyWrapper {
  flex-grow: 1;
  margin-top: 3rem;
}

.viewport {
  display: flex;
  flex-direction: column;
}
