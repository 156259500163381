@use '@carbon/colors';
@use '@carbon/layout';
@use '@carbon/type';

.welcome-page {
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 3rem;

  .welcome-header {
    margin-bottom: 1rem;
    
    h2 {
      @include type.type-style('heading-03');
      margin-bottom: 0;
    }
  }

  .auth-banner {
    background: linear-gradient(90deg, colors.$blue-50 0%, colors.$blue-70 100%);
    border-radius: 0.25rem;
    margin-bottom: 2rem;
    padding: 1rem;
    
    .auth-message {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: colors.$white;
      
      p {
        margin: 0;
      }
    }
    
    .auth-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem;
      justify-content: flex-end;
      
      button {
        min-width: 150px;
        
        &:first-child {
          background-color: colors.$white;
          color: colors.$blue-70;
          
          &:hover {
            background-color: colors.$gray-10;
          }
        }
        
        &:nth-child(2) {
          background-color: colors.$gray-10;
          color: colors.$gray-100;
          border: 1px solid colors.$gray-50;
          
          &:hover {
            background-color: colors.$gray-20;
          }
        }
      }
    }
  }

  .main-content {
    margin-bottom: 2rem;
    
    .forward-supplier-tile {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      background-color: colors.$blue-10;
      border-left: 4px solid colors.$blue-60;
      
      .forward-supplier-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      
      .forward-supplier-info {
        h4 {
          @include type.type-style('heading-01');
          margin-top: 0;
          margin-bottom: 0.5rem;
          color: colors.$blue-70;
        }
        
        p {
          margin: 0;
          color: colors.$gray-80;
        }
      }
      
      .forward-button {
        flex-shrink: 0;
        min-width: 180px;
      }
    }
    
    .intro-tile {
      height: 100%;
      background-color: colors.$white;
      padding: 1.5rem;
      
      h2 {
        @include type.type-style('heading-03');
        margin-top: 0;
      }
      
      p {
        margin-bottom: 1.5rem;
      }
    }
    
    .factory-image {
      width: 100%;
      max-width: 300px;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .section-heading {
    margin: 2rem 0 1rem;
    
    h3 {
      @include type.type-style('heading-03');
      margin-bottom: 0.5rem;
    }
    
    p {
      @include type.type-style('body-01');
      color: colors.$gray-70;
    }
  }

  .video-section {
    .full-walkthrough {
      margin-bottom: 2rem;
      
      .bx--tile {
        padding: 1.5rem;
      }
      
      h4 {
        @include type.type-style('heading-02');
        margin-top: 0;
        margin-bottom: 1rem;
      }
      
      .featured-video {
        margin-bottom: 1rem;
      }
      
      .video-description {
        p {
          margin: 0;
        }
      }
    }
  }

  .tutorial-section {
    .video-column {
      margin-bottom: 2rem;
      
      .bx--tile {
        height: 100%;
        padding: 1.5rem;
      }
      
      h4 {
        @include type.type-style('heading-01');
        margin-top: 0;
        margin-bottom: 1rem;
      }
      
      .video-container {
        margin-bottom: 1rem;
      }
      
      .video-description {
        p {
          margin: 0;
          @include type.type-style('body-01');
        }
      }
    }
  }
}

// Media queries for responsive design
@media (max-width: 672px) {
  .welcome-page {
    .auth-banner {
      .auth-message, .auth-buttons {
        justify-content: center;
        text-align: center;
      }
      
      .auth-buttons {
        margin-top: 1rem;
      }
    }
    
    .main-content {
      .forward-to-supplier {
        flex-direction: column;
        text-align: center;
        
        p {
          margin-bottom: 1rem;
        }
      }
    }
  }
}