@use '@carbon/react/scss/spacing' as *;
@use '@carbon/colors';


#language-switcher .cds--list-box__menu {
  max-width: 20rem;
  min-width: 8rem;
  right: 0px;
  left: auto;
}



.header-logo {
  height: $spacing-09; /* Adjust the height of the logo */
  margin-right: $spacing-05; /* Space between the logo and the title */
}

#supplier-name {
  color: colors.$black !important;
  padding-left: 100px;
  font-weight: bold;
}
