@use "@carbon/colors";

.dropdown .dropdown-menu {
  display: block;
  top: 100%;
  left: 0;
  border: none !important;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  background-color: colors.$black;
  cursor: pointer;
  width: 100%;
}

.dropdown-toggle,
.dropdown-item {
  font-size: 2rem !important;
  padding: 0 1rem !important;
  align-items: center;
}