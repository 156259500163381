// Remove Precursor close button to fix mandatory validation logic

.custom-multiselect-no-clear .cds--tag__close-icon{
    display: none !important;
  }


  .custom-multiselect-no-clear .cds--tag__label{
    margin-right: 0.5rem;
  }

  // Fix precursor dropdown being above modal
  .cds--modal-container{
    z-index: 4000 !important;
  }
  .cds--list-box__menu{
    z-index: 3000!important;
  }
