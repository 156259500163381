@use '@carbon/react/scss/themes';
@use '@carbon/react/scss/theme';
@use '@carbon/styles';
@use '@carbon/themes/scss/tokens';
@use '@carbon/react/scss/spacing';
@use '@carbon/grid';
@use '@carbon/type';

// Include type reset
@include type.reset();

// Include default type styles, targets h1, h2, h3, etc
@include type.default-type();

// Include utility classes for type-related properties
@include type.type-classes();

.mvp-h1-compact {
  @include type.type-style('heading-compact-01');
}

.mvp-h1 {
  @include type.type-style('heading-01');
}

.mvp-h3 {
  @include type.type-style('heading-03');
}

.mvp-h4 {
  @include type.type-style('heading-04');
}

.mvp-h2-compact {
  @include type.type-style('heading-compact-02');
  margin-top: spacing.$spacing-04;
  margin-bottom: spacing.$spacing-03;
}

.mvp-body-01 {
  @include type.type-style('body-01');
}

.full-width-button {
  width: 100%;
  max-width: 100%;
}

.mvp-h3 {
  @include type.type-style('heading-03');
  margin-top: spacing.$spacing-08;
  margin-bottom: spacing.$spacing-08;
}

.mvp-h4 {
  @include type.type-style('heading-compact-01');
  margin-bottom: spacing.$spacing-05;
}

.full-width-button {
  width: 100%;
  max-width: 100%;
}

html {
  // for datatable overflow-x
  // if this isnt in, the background color wont be themed
  // alternatives: position:absolute
  // please replace if you know of anything not this... interesting
  display: table;
  min-width: 100%;
}
