

.action-buttons {
    
    padding-left: 0 !important;
    padding-right: 0 !important;;
  }

  .mandatory-field {
    padding-right: 0 !important;;
  }

  .table-header-actions {
    text-align: right; /* Align the header to the right */
  }

.primary-overflow-button {
  background-color: var(--cds-interactive-01, #0f62fe); /* This is the Carbon blue color variable */
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.primary-overflow-button:hover {
    background-color: var(--cds-hover-primary, #0353e9); /* Darker blue on hover */
  }
  
  .primary-overflow-button:active,
  .primary-overflow-button:focus  {
    background-color: var(--cds-active-primary, #002d9c); /* Even darker blue on click */
  }
  
  .cds--overflow-menu--open{
    background-color: var(--cds-active-primary, #002d9c) !important; /* Even darker blue on click */
  }

  
  .cds--number[data-invalid] input[type=number]:not(:focus) {
    margin-top: 0.25rem !important; // This will now only apply to invalid number inputs
}


  .activity-input-wrapper {
    min-width: 0;
    width: 130px;
  }

  .unit-select-wrapper{
    margin-top: -0.5rem !important;
    width: 5rem !important;
  }

  
  // .dropdown-container {
  //   margin-bottom: 300px !important; /* Adjust the value as needed */
  // }