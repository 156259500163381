.status-table-right,
.status-table-left {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.status-table-left {
    margin-right: 0 !important;
}

// .status-table-right {
//     margin-left: 0 !important;
// }

.status-submit-btn-100 {
    height: 100% !important;
}

.status-submit-btn-50 {
    width: 100% !important;
    height: 100% !important;
}

