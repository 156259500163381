@use '@carbon/grid';
@use '@carbon/layout';
@use "@carbon/colors";

// Emit the flex-grid styles
@include grid.flex-grid();

.cora-progress-indicator {
  margin-bottom: layout.$spacing-07;

  .cds--progress-step {}
}

.survey-section {
  margin-top: 3rem;
  margin-bottom: 2rem;

  margin-left: 0rem !important;
}


.cora-form-container-step9 {
  margin: 0rem 0;
  padding: 0rem;

}

.cora-form-container {
  background-color: colors.$white;
  margin: 2rem 0;
  padding: 2rem;
}

// Common styles for all the steps
.form-step .cds--css-grid-column {
  margin-top: 2rem;
}

.grid-container-element { 
  display: grid; 
  grid-template-columns: 2fr 1fr; 
  grid-gap: 20px; 
  width: 100%; 
} 
.grid-child-element { 
  margin: 10px; 
}

.step-btn-set-container{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--cds-background);
  z-index: 1000;
}
.step-btn-set {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.faq-sidebar{
  float:right;
  width:100%;
  background-color:colors.$white;
}

/* Media queries for different breakpoints */
@media screen and (min-width: 20rem) {
  .step-btn-set {
    padding: 16px;
    margin-left: 0px;
  }
}

@media screen and (min-width: 42rem) {
  .step-btn-set {
    padding: 16px;
    margin-left: 16px;
  }
}

@media screen and (min-width: 66rem) {
  .step-btn-set {
    padding: 16px;
    margin-left: 16px;
  }
}

@media screen and (min-width: 82rem) {
  .step-btn-set {
    padding: 16px;
    margin-left: 16px;
  }
}

@media screen and (min-width: 99rem) {
  .step-btn-set {
    padding: 16px;
    max-width: 96rem;
    margin: auto;
  }
}
